<template>
  <div class="urgency-details py-8">
    <UrgencyAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        :model="urgency"
        name="urgency"
        redirect="/maintenance/urgencies"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="urgency-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="urgency-details__divider"></v-divider>

            <UrgencyForm :urgency="urgency" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Maintenance urgency
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import UrgencyForm from '@/views/Home/Maintenance/Urgencies/components/UrgencyForm'
import UrgencyAppbar from '@/views/Home/Maintenance/Urgencies/components/UrgencyAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'

export default {
  name: 'UrgencyDetails',

  components: {
    ViewingTitle,
    UrgencyAppbar,
    UrgencyForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      urgency: (state) => state.urgency.urgencyDetails,
    }),

    displayName() {
      return this.urgency ? this.urgency.label : null
    },
  },

  methods: {
    ...mapActions({
      getUrgencyDetails: 'urgency/getUrgencyDetails',
    }),

    ...mapMutations({
      clearUrgencyDetails: 'urgency/clearUrgencyDetails',
    }),

    async getUrgency() {
      this.loading = true
      await this.getUrgencyDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getUrgency()
  },

  destroyed() {
    this.clearUrgencyDetails()
  },

  watch: {
    $route() {
      this.getUrgency()
    },
  },
}
</script>

<style lang="scss" scoped>
.urgency-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
